<template>
    <div class="signIn">
        <div class="box">
            <div class="top">
                <img src="@/assets/img/logo.png" class="logo">
                <div class="title">
                    创建账号
                </div>
            </div>
            <div class="form">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                    <el-form-item label="请输入用户名称" prop="nick">
                        <el-input v-model="ruleForm.nick"></el-input>
                    </el-form-item>
                    <el-form-item label="请输入手机号码" prop="phone">
                        <el-input v-model="ruleForm.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="请输入验证码" prop="code">
                        <el-input v-model="ruleForm.code" class="code-input"></el-input>
                        <div class="verification-code" v-if="show" @click="getCode">获取验证码</div>
                        <div class="verification-code" v-if="!show" >倒数 {{count}} s</div>
                    </el-form-item>
                     <el-form-item label="请设置密码" prop="password">
                         <div class="type-icon">
                            <div>
                                <i v-if="inputType" class="icon iconfont icon-yanjing" @click="typeBtn"></i>
                                <i v-else class="icon iconfont icon-yanjing-bi" @click="typeBtn"></i>
                            </div>
                        </div>
                        <el-input :type="inputType?'text':'password'" v-model="ruleForm.password"></el-input>
                    </el-form-item>
                    <el-form-item label="邀请码">
                        <el-input v-model="ruleForm.sid" disabled placeholder="请联系工作人员/邀请人发送邀请链接"></el-input>
                    </el-form-item>
                </el-form>
                <button class="login-btn" style="background: #BABEC7;color:#ffffff;" v-if="!btnColor">注册</button>
                <button class="signIn-btn" @click="submitForm" v-else>注册</button>
                <div class="or-box">
                    <span class="or">
                        或
                    </span>
                </div>
                <button class="login-btn" @click="goVerify">
                    <img src="@/assets/img/user.png">
                    已有账号，去登录
                </button>
            </div>
        </div>
        <div class="footer">
            <img src="@/assets/img/2.png" class="m-img">
        </div>
    </div>
</template>
<script>
import md5 from 'js-md5';
export default {
    data(){
        return{
            ruleForm:{
                nick:'',
                phone:'',
                code: '',
                password:'',
                sid:'',
                role:''
            },
            query:{
                app_id:'',
                notify_url:''
            },
            ispassword:true,
            // 验证码显示状态
            show: true,
            // 倒计时秒数
            count: '',
            // 定时器
            timer: null,
            rules:{
                nick: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入11位数的手机号码', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { min: 6, max: 6, message: '请输入6位数的验证码', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 18, message: '请输入6字符到18字符的密码', trigger: 'blur' }
                ],
            },
            // type类型
            inputType:false
        }
    },
    created(){
        console.log(this.$route.query)
        this.query=this.$route.query
        if(this.$route.query.phone){
            this.ruleForm.phone=this.query.phone
        }else{
            this.ruleForm.phone=''
        }
        this.ruleForm.sid=this.$route.query.sid
        this.ruleForm.nick=this.$route.query.name
        // 校验手机是否注册
        this.verify()
    },
    computed:{
        btnColor(){
            if(!this.ruleForm.nick){
                return false
            }else if(!this.ruleForm.phone){
                return false
            }else if(!this.ruleForm.code){
                return false
            }else if(!this.ruleForm.password){
                return false
            }else if(!this.ruleForm.sid){
                return false
            }
            return true
        }
    },
    methods:{
        // 注册前校验
        async verify(){
            const {data:res} = await this.$http.get('/user_is_exist',{
                params:{
                    phone:this.ruleForm.phone,
                    app_id:this.query.app_id
                }
            })
            if(res.status==200) {
                // 已有账号跳转到登录
                this.goLogin(res)
            }
        },
        // 注册
        submitForm() {
            this.$refs.ruleForm.validate( async(valid) => {
                if(!valid) return 
                const{data:res} = await this.$http.post('/register',{
                    phone:this.ruleForm.phone,
                    code:this.ruleForm.code,
                    password:md5(this.ruleForm.password),
                    nick:this.ruleForm.nick,
                    app_id:this.query.app_id,
                    sid:this.ruleForm.sid,
                    role:this.query.role
                })
                if(res.status!==200) return this.$message({ showClose: true, message: res.message, type: 'error' });
                this.$message({ showClose: true, message: '注册成功', type: 'success' });
                // 注册成功跳转登录
                this.goVerify()
            });
        },
        // 获取验证码
        async getCode(){
            if(this.ruleForm.phone.length!==11) return this.$message({ showClose: true, message: '请输入正确手机号码再获取验证码', type: 'error' });
            const {data:res} = await this.$http.get('/verification_code',{
                params:{
                    phone:this.ruleForm.phone,
                    sing_code:'WINDATA'
                }
            })
            if(res.status!==200) return this.$message({ showClose: true, message: '获取验证码失败，请获取', type: 'error' });
            this.$message({ showClose: true, message: '获取验证码成功，请留意短信', type: 'success' });
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                    } else {
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000)
            }
        },
        // GO校验页面
        goVerify(){
            this.$router.push('/verify?app_id='+this.query.app_id+'&notify_url='+this.query.notify_url)
        },
        // GO登录页面
        goLogin(res){
            this.$router.push({ path: '/login', query: { 
                phone: this.ruleForm.phone,
                image: res.data.image,
                nike:res.data.nike,
                app_id:this.query.app_id,
                notify_url:this.query.notify_url,
                sid:this.query.sid,
                role:this.query.role,
                name:this.query.name
            }})
        },
        typeBtn(){
            this.inputType=!this.inputType
        }
    }
}
</script>
<style lang="less" scoped>
.signIn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #F6F6F6;
    flex-direction: column;
    // overflow-y: scroll;
    .box{
        width: 32.5rem;
        // height: 44rem;
        background-color: #ffffff;
        box-shadow: 0px 0px 0.375rem rgba(0, 0, 0, 0.06);
        margin-top: -0.625rem;
        padding-bottom: 4.375rem;
        .top{
            z-index: 10;
            width: 32.5rem;
            height: 3.625rem;
            background-color: #1A54EC;
            .logo{
                width: 3.625rem;
                height: 3.625rem;
                position: absolute;
                margin-left: 0.625rem;
                margin-left: 4.6875rem;
            }
            .title{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 3.5625rem;
                color: #FFFFFF;
                font-size: 1.25rem;
            }
        }
        .form{
            margin-top: 3.125rem;
            text-align: center;
            padding: 0 5.9375rem;
            /deep/.el-input__inner{
                margin-top: 1.25rem;
                height: 2.75rem;
                // line-height: 2.75rem;
            }
            button{
                height: 2.75rem;
                width: 100%;
                margin-top: 1.25rem;
                border-radius: 0.125rem;
                font-size: 0.875rem;
            }
            button:hover{
                cursor:pointer;
            }
            .or-box{
                color: #828494;
                margin-top: 1.875rem;
                position: relative;
                span{
                    font-size: 0.875rem;
                }
            }
            .or::before{
                content: '';
                width: 7.125rem;
                height: 0.125rem;
                background-image: linear-gradient(to right, #DCDFE3 , #ffffff);
                position: absolute;
                top: 12px;
                margin-left: 1.875rem;
            }
            .or::after{
                content: '';
                width: 7.125rem;
                height: 0.125rem;
                background-image: linear-gradient(to left, #DCDFE3 , #ffffff);
                position: absolute;
                top: 12px;
                margin-left: -9rem;
            }
        }
    }
    
}

.pc-img{
    margin-top: 1.875rem;
    width: 32.5rem;
    height: 6.1875rem;
}
.footer{
    display: none;
}
.el-form{
    position: relative;
}
/deep/.el-form-item__label{
    position: absolute;
    height: 1.125rem;
    line-height: 1.125rem;
    left: 0.625rem;
    margin-top: 0.75rem;
    z-index: 9;
    background-color: #ffffff;
    color: #828494;
    padding-left: 0.4375rem;
    font-size: 0.75rem;
}
/deep/.el-form-item{
    margin-bottom: 0.3125rem;
    position: relative;
    .type-icon{
        position: absolute;
        z-index: 10;
        right: 0.75rem;
        height: 2.75rem;
        margin-top: 1.25rem;
        div{
            display: flex;
            align-items: center;
            height: 100%;
            .iconfont{
                font-size: 1.25rem;
                color: #BABEC7;
            }
        }
    }
}
.code-input{
    width: 13rem;
}
/deep/.el-form-item__content{
    display: flex;
}
.verification-code{
    width: 7rem;
    height: 2.75rem;
    background-color: #1A54EC;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin: 1.25rem 0 0 0.625rem;
    border-radius: 0.125rem;
    font-size: 0.75rem;
}
.signIn-btn{
    border: none;
    color: #FFFFFF;
    background-color: #1A54EC;
}
.login-btn{
    border: 1px solid #BABEC7;
    background: #FFFFFF;
    color: #828494;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width:1.0688rem;
        height:1.0987rem;
        margin-right: 0.625rem;
    }
}
/deep/.el-form-item__error{
    font-size: 0.75rem;
    padding-top: 0;
    line-height: 0.75rem;
}
@media  screen and (max-width:750px ) {
    .signIn{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        background-color: #ffffff;
        box-sizing: border-box;
        flex-direction: column;
        padding-top: 58px;
        .box{
            width: 100%;
            box-shadow: none;
            height: 704px;
            background-color: #ffffff;
            .top{
                z-index: 10;
                width: 100%;
                height: 58px;
                background-color: #1A54EC;
                position: absolute;
                top: 0;
                .logo{
                    width: 58px;
                    height: 58px;
                    position: absolute;
                    margin-left: 10px;
                }
                .title{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 57px;
                    color: #FFFFFF;
                    font-size: 16px;
                }
            }
            .form{
                margin-top: 50px;
                text-align: center;
                padding: 0 30px;
                /deep/.el-input__inner{
                    margin-top: 20px;
                    height: 44px;
                    // line-height: 44px;
                }
                button{
                    height: 44px;
                    width: 100%;
                    margin-top: 20px;
                    border-radius: 2px;
                    font-size: 14px;
                }
                button:hover{
                    cursor:pointer;
                }
                .or-box{
                    color: #828494;
                    margin-top: 30px;
                    position: relative;
                    span{
                        font-size: 14px;
                    }
                }
                .or::before{
                    content: '';
                    width: 114px;
                    height: 2px;
                    background-image: linear-gradient(to right, #DCDFE3 , #ffffff);
                    position: absolute;
                    top: 10px;
                    margin-left: 30px;
                }
                .or::after{
                    content: '';
                    width: 114px;
                    height: 2px;
                    background-image: linear-gradient(to left, #DCDFE3 , #ffffff);
                    position: absolute;
                    top: 10px;
                    margin-left: -144px;
                }
            }
        }
        
    }

    .pc-img{
        display: none;
    }
    .footer{
        display: block;
        background-color: #ffffff;
        padding: 30px 0;
    }
    .el-form{
        position: relative;
    }
    /deep/.el-form-item__label{
        position: absolute;
        height: 18px;
        line-height: 18px;
        left: 10px;
        margin-top: 12px;
        z-index: 9;
        background-color: #ffffff;
        color: #828494;
        padding-left: 7px;
        font-size: 12px;
    }
    /deep/.el-form-item{
        margin-bottom: 8px;
        .type-icon{
            position: absolute;
            z-index: 10;
            right: 12px;
            height: 44px;
            margin-top: 20px;
            div{
                display: flex;
                align-items: center;
                height: 100%;
                .iconfont{
                    font-size: 20px;
                    color: #BABEC7;
                }
            }
        }
    }
    .code-input{
        width: 208px;
    }
    /deep/.el-form-item__content{
        display: flex;
    }
    .verification-code{
        width: 112px;
        height: 44px;
        background-color: #1A54EC;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        margin: 20px 0 0 10px;
        border-radius: 2px;
        font-size: 14px;
    }
    .signIn-btn{
        border: none;
        color: #FFFFFF;
        background-color: #1A54EC;
    }
    .login-btn{
        border: 1px solid #BABEC7;
        background: #FFFFFF;
        color: #828494;
        img{
            width:17.1008px;
            height:17.5792px;
            margin-right: 10px;
        }
    }
    /deep/.el-form-item__error{
        font-size: 12px;
        padding-top: 4px;
        line-height: 1;
    }
}
</style>